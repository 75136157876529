import axios from "axios";
import Article from "./article";
import DataLabeling from "./dataLabeling";

axios.defaults.headers = {
  Accept: "application/json",
};

export default class Services {
  static Article = Article;
  static DataLabeling = DataLabeling;
}
