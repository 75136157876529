import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enable: false,
  articles: [],
  progress: {},
};

const articleSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setEnableArticle: (state, action) => {
      state.enable = action.payload;
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    resetArticles: (state) => {
      state.articles = [];
    },
    setProgress: (state, action) => {
      if (Object.keys(state.progress).includes(action.payload.id)) {
        state.progress[action.payload.id].currentArticle =
          action.payload.currentArticle;
      } else {
        state.progress[action.payload.id] = {
          currentArticle: 1,
        };
      }
    },
    resetProgress: (state) => {
      state.progress = {};
    },
  },
});

export const {
  setEnableArticle,
  setArticles,
  resetArticles,
  setProgress,
  resetProgress,
} = articleSlice.actions;
export default articleSlice.reducer;
