import React, { useState } from "react";
import "./css/ArticleBox.css"; // Import the CSS file for styling
import { useNavigate } from "react-router-dom";

const ArticleBox = ({ id, title, level, description, authorName, time }) => {
  const navigate = useNavigate();

  const [navigating, setNavigating] = useState(false);

  const navigateToArticlePage = async (event) => {
    if (event.target.tagName === "BUTTON") {
      if (!navigating) {
        setNavigating(true);
        navigate(`/article/${id}`);
      }
    } else {
      if (!navigating) {
        setNavigating(true);
        navigate(
          `/article/${id}?outline=true`
        );
      }
    }
  };

  return (
    <div className="article-box" onClick={navigateToArticlePage}>
      <div className="article-header">
        {/* <h3>ARTICLE</h3> */}
        <h2>{title}</h2>
      </div>
      <div className="article-level">
        <span className="beginner">{level}</span>
      </div>
      <p className="article-description">{description}</p>
      <div className="article-author">
        {/* <img src={authorImage} alt="Author" className="author-image" /> */}
        <div>
          <span className="author-name">{authorName}</span>
          {/* <span className="author-title">{authorTitle}</span> */}
        </div>
      </div>
      <div className="article-footer">
        {time ?? <span className="time">{time}</span>}
        <button className="start-button" onClick={navigateToArticlePage}>
          Read
        </button>
      </div>
    </div>
  );
};

export default ArticleBox;
