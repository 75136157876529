import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import GetLocationList from '../components/GetLocationList';
import GetHistoryData from '../components/GetHistoryData';
import GetPredictData from '../components/GetPredictData';
import GetNews from '../components/GetNews';
import Select from 'react-select';
import { transformDataTable } from '../components/TransformedData';
import { DropdownButton, ButtonGroup, Dropdown, Button, Form, InputGroup, ToggleButton, Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import 'fontawesome-free/css/all.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { signIn, signOut, fetchAuthSession } from 'aws-amplify/auth';
import ModelForHeatIndex from '../components/common/ModelForHeatIndex';
import ContentLocker from '../components/common/ContentLocker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import '../App.css';
import 'leaflet/dist/leaflet.css';
import ArticleList from "../components/ArticleList";
import Services from "../services";
import { useDispatch, useSelector } from "react-redux";
import { resetArticles, setArticles } from "../states/features/article/articleSlice";
import { DataLabelingPage } from "./DataLabelingPage";
import { setJobList } from "../states/features/dataLabeling/dataLabelingSlice";

const HistoryPage = (props) => {
    const articles = useSelector((state) => state.article.articles);
    const [loadingArticles, setLoadingArticles] = useState(true);
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bigLineChartOptions = useMemo(() => {
        return {
            chart: {
                id: 'chart2',
                type: 'line',
                height: 230,
                toolbar: {
                    autoSelected: 'pan',
                    show: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            plotOptions: {
                candlestick: {
                    colors: {
                        upward: '#72D4FF',
                        downward: '#72D4FF'
                    }
                }
            },
            colors: ['#72D4FF'],
            stroke: {
                width: 2
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                opacity: 1,
            },
            markers: {
                size: 0
            },
            annotations: {
                yaxis: [{
                    y: 0,
                    y2: 32.8,
                    borderColor: '#000',
                    fillColor: '#FFF103',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#FFF103',
                        },
                        // text: 'Lower(Caution)',
                    }
                }, {
                    y: 32.8,
                    y2: 39.4,
                    borderColor: '#000',
                    fillColor: '#FEB019',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#FEB019'
                        },
                        // text: 'Moderate',
                    }
                }, {
                    y: 39.4,
                    y2: 46.1,
                    borderColor: '#000',
                    fillColor: '#F3722C',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#F3722C',
                        },
                        // text: 'High',
                    }
                }, {
                    y: 46.1,
                    y2: 100,
                    borderColor: '#000',
                    fillColor: '#D1232A',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#ffffff',
                            background: '#D1232A',
                        },
                        // text: 'Very hight to extreme',
                    }
                }
                ],
                xaxis: []
            },
            xaxis: {
                type: 'datetime'
            },
            yaxis: [
                {
                    seriesName: ["Heat Index"],
                    title: {
                        text: "Degree (°C)",
                    },
                    // labels: {
                    //     formatter: (value) => Math.round(value) 
                    // }
                },
            ]
        }
    }, []);

    const smallLineChartOptions = useMemo(() => {
        var currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 3);
        return {
            chart: {
                id: 'chart1',
                height: 130,
                type: 'area',
                brush: {
                    target: 'chart2',
                    enabled: true
                },
                selection: {
                    enabled: true,
                    xaxis: {
                        min: currentDate.getTime(),
                        max: new Date().getTime()
                    }
                },
            },
            plotOptions: {
                candlestick: {
                    colors: {
                        upward: '#72D4FF',
                        downward: '#72D4FF'
                    }
                }
            },
            colors: ['#72D4FF'],
            fill: {
                type: 'gradient',
                gradient: {
                    opacityFrom: 1,
                    opacityTo: 1,
                }
            },
            xaxis: {
                type: 'datetime',
                tooltip: {
                    enabled: false
                }
            },
            // yaxis: {
            //     tickAmount: 1,
            // labels: {
            //     formatter: (value) => Math.round(value) 
            // }
            // }
        }
    }, []);
    const heatIndexAnnotation = useMemo(() => {
        return {
            yaxis: [{
                y: 0,
                y2: 32.8,
                borderColor: '#000',
                fillColor: '#FFF103',
                opacity: 0.2,
                label: {
                    offsetY: 20,
                    borderColor: '#333',
                    style: {
                        fontSize: '10px',
                        color: '#333',
                        background: '#FFF103',
                    },
                    // text: 'Lower(Caution)',
                }
            }, {
                y: 32.8,
                y2: 39.4,
                borderColor: '#000',
                fillColor: '#FEB019',
                opacity: 0.2,
                label: {
                    offsetY: 20,
                    borderColor: '#333',
                    style: {
                        fontSize: '10px',
                        color: '#333',
                        background: '#FEB019'
                    },
                    // text: 'Moderate',
                }
            }, {
                y: 39.4,
                y2: 46.1,
                borderColor: '#000',
                fillColor: '#F3722C',
                opacity: 0.2,
                label: {
                    offsetY: 20,
                    borderColor: '#333',
                    style: {
                        fontSize: '10px',
                        color: '#333',
                        background: '#F3722C',
                    },
                    // text: 'High',
                }
            }, {
                y: 46.1,
                y2: 100,
                borderColor: '#000',
                fillColor: '#D1232A',
                opacity: 0.2,
                label: {
                    offsetY: 20,
                    borderColor: '#333',
                    style: {
                        fontSize: '10px',
                        color: '#ffffff',
                        background: '#D1232A',
                    },
                    // text: 'Very hight to extreme',
                }
            }
            ]
        }
    }, []);
    const miniChartOptions = useMemo(() => {
        var currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        return {
            chart: {
                id: 'area-datetime',
                type: 'area',
                height: 350,
                zoom: {
                    autoScaleYaxis: true
                }
            },
            annotations: {
                yaxis: [{
                    y: 0,
                    y2: 32.8,
                    borderColor: '#000',
                    fillColor: '#FFF103',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#FFF103',
                        },
                        // text: 'Lower(Caution)',
                    }
                }, {
                    y: 32.8,
                    y2: 39.4,
                    borderColor: '#000',
                    fillColor: '#FEB019',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#FEB019'
                        },
                        // text: 'Moderate',
                    }
                }, {
                    y: 39.4,
                    y2: 46.1,
                    borderColor: '#000',
                    fillColor: '#F3722C',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#F3722C',
                        },
                        // text: 'High',
                    }
                }, {
                    y: 46.1,
                    y2: 100,
                    borderColor: '#000',
                    fillColor: '#D1232A',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#ffffff',
                            background: '#D1232A',
                        },
                        // text: 'Very hight to extreme',
                    }
                }
                ]
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 2
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            xaxis: {
                type: 'datetime',
                min: currentDate.getTime(),
                tickAmount: 6,
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            // yaxis: {
            // labels: {
            //     formatter: (value) => Math.round(value) 
            // }
            // },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            }
        }
    }, []);
    const [citylist, setCityList] = useState([]);

    const mailtoLink = `mailto:${process.env.REACT_APP_MAIL_TO}?subject=${encodeURIComponent(process.env.REACT_APP_MAIL_REPORT_SUBJECT)}&body=${encodeURIComponent(process.env.REACT_APP_MAIL_REPORT_BODY)}`;
    const [page, setPage] = useState('summary');
    const [chartState, setChartState] = useState({
        series: [{
            data: []
        }],
        options: bigLineChartOptions,
        seriesLine: [{
            data: []
        }],
        optionsLine: smallLineChartOptions,
        miniChartSeries: [{
            data: []
        }],
        miniChartOptions: miniChartOptions
    });
    const [transformData, setTransformData] = useState({});
    const locationRadios = [
        { name: 'Country ', value: 'Country' },
        { name: 'Location', value: 'Location' }
    ];
    const [chartLoading, setChartLoading] = useState(true)
    const [selectLocationType, setSelectLocationType] = useState("Country")
    const [selectLocationTypeTemp, setSelectLocationTypeTemp] = useState("Country")
    const [selectCompareLocationType, setSelectCompareLocationType] = useState("Country")
    const [selectedCountry, setSelectedCountry] = useState({ value: 'Bangkok Metropolis', label: 'Bangkok Metropolis', lon: 100.5, lat: 13.75 });
    const [selectedCountryTemp, setSelectedCountryTemp] = useState({ value: 'Bangkok Metropolis', label: 'Bangkok Metropolis', lon: 100.5, lat: 13.75 });
    const [selectedCompareCountry, setSelectedCompareCountry] = useState({ value: "Amnat Charoen", label: "Amnat Charoen", lon: 104.82, lat: 15.84 });
    const [latitude, setLatitude] = useState(13.75);
    const [latitudeTemp, setLatitudeTemp] = useState(13.75);
    const [compareLatitude, setCompareLatitude] = useState(15.84);
    const [longitude, setLongitude] = useState(100.5);
    const [longitudeTemp, setLongitudeTemp] = useState(100.5);
    const [compareLongitude, setCompareLongitude] = useState(104.82);
    const [selectedIndicatior, setSelectedIndicatior] = useState({ value: 'max', label: 'Maximum' });
    const [indicatiorRadioValue, setIndicatiorRadioValue] = useState('variables');
    const [modalShow, setModalShow] = useState(false);
    const [isSubscribeUser, setIsSubscribeUser] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [username, setUsername] = useState('');
    const [indicatiorOption, setIndicatiorOption] = useState([
        { value: 'max', label: 'Maximum' },
        { value: 'min', label: 'Minimum' },
        // { value: 'min_max', label: 'Minimum-Maximum' },
        { value: 'mean', label: 'Mean' },
        { value: 'median', label: 'Median' }
    ])
    const typeOptions = [
        { value: 'heatidx', label: 'Heat Index', unit: 'Degree (°C)', color: "#72D4FF" },
        { value: 'temp', label: 'Surface Temperature', unit: 'Degree (°C)', color: "#AD6EFF" },
        { value: 'temp2m', label: 'Temperature', unit: 'Degree (°C)', color: "#FF80C4" },
        { value: 'relhum', label: 'Relative Humidity', unit: 'Percent (%)', color: "#FFBD74" },
        { value: 'precip', label: 'Rain Rate', unit: 'mm/day', color: "#64F1D9" },
        { value: 'accum_rain_rate', label: 'Annual Accumulated Rain Rate', unit: 'mm/year', color: "#002FEE" },
        { value: 'tpw', label: 'Precipitable Water', unit: 'mm', color: "#E5622A" }
    ];
    const [selectType, setSelectType] = useState({ value: 'heatidx', label: 'Heat Index', unit: 'Degree (°C)', color: "#72D4FF" })
    const [compareTypeOption, setCompareTypeOption] = useState([
        { value: 'temp', label: 'Surface Temperature', unit: 'Degree (°C)', color: "#AD6EFF" },
        { value: 'temp2m', label: 'Temperature', unit: 'Degree (°C)', color: "#FF80C4" },
        { value: 'relhum', label: 'Relative Humidity', unit: 'Percent (%)', color: "#FFBD74" },
        { value: 'precip', label: 'Rain Rate', unit: 'mm/day', color: "#64F1D9" },
        { value: 'accum_rain_rate', label: 'Annual Accumulated Rain Rate', unit: 'mm/year', color: "#002FEE" },
        { value: 'tpw', label: 'Precipitable Water', unit: 'mm', color: "#E5622A" }
    ])
    const indicatiorRadios = [
        { name: 'Variables', value: 'variables' },
        { name: 'Locations', value: 'locations' },
        { name: 'Year Over Year', value: 'history' },
    ];
    const [selectedCompareTypeOptions, setSelectedCompareTypeOptions] = useState([]);
    const [compareLocationList, setCompareLocationList] = useState([])
    const [xaxisline, setXaxisline] = useState(null);
    const [predictLenght, setPredictLenght] = useState(null);
    const [isLoadingClimate, setIsLoadingClimate] = useState(true);
    const [climateData, setClimateData] = useState([]);
    // const historyRadios = [
    //   { name: 'Year', value: 'year' },
    //   { name: 'Month', value: 'month' }
    // ];
    const yearOptions = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
    const [yearList, setYearList] = useState([2023, 2024])
    const [tableData, setTableData] = useState({
        data: [], cols: [
            // { field: 'dateFormatted', header: 'Date' },
            { field: 'date', header: 'Date' },
            { field: 'T0', header: '00:00' },
            { field: 'T6', header: '06:00' },
            { field: 'T12', header: '12:00' },
            { field: 'T18', header: '18:00' },
            { field: 'min', header: 'Min' },
            { field: 'max', header: 'Max' },
            { field: 'mean', header: 'Mean' },
            { field: 'median', header: 'Median' }
        ]
    });
    const dt = useRef(null);
    const navigate = useNavigate();

    const cols_rain_rate = [
        // { field: 'dateFormatted', header: 'Date' },
        { field: 'date', header: 'Date' },
        { field: 'T0', header: '00:00' },
        { field: 'T6', header: '06:00' },
        { field: 'T12', header: '12:00' },
        { field: 'T18', header: '18:00' },
        { field: 'sum', header: 'Rain Rate' },
        { field: 'accum_rain_rate', header: 'Annual Accumulated Rain Rate' }
    ];

    const cols_other = [
        // { field: 'dateFormatted', header: 'Date' },
        { field: 'date', header: 'Date' },
        { field: 'T0', header: '00:00' },
        { field: 'T6', header: '06:00' },
        { field: 'T12', header: '12:00' },
        { field: 'T18', header: '18:00' },
        { field: 'min', header: 'Min' },
        { field: 'max', header: 'Max' },
        { field: 'mean', header: 'Mean' },
        { field: 'median', header: 'Median' }
    ];
    const [news, setNews] = useState([]);
    const [newsAll, setNewsAll] = useState([]);
    const [newsLoading, setNewsLoading] = useState(true);
    const selectionRadios = ["1M", "3M", "6M", "YTD", "1Y", "3Y", "5Y", "All"]
    const [selection, setSelection] = useState('1M');
    const [selectionChart, setSelectionChart] = useState('3M');
    const [selectionTable, setSelectionTable] = useState('3M');
    const [selectionNewsDate, setSelectionNewsDate] = useState('All');
    const [latestDate, setLatestDate] = useState(null);
    const [tableStartDate, setTableStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
    const [tableEndDate, setTableEndDate] = useState(new Date());

    const [newsStartDate, setNewsStartDate] = useState(null);
    const [newsStartDateTemp, setNewsStartDateTemp] = useState(null);
    const [newsEndDate, setNewsEndDate] = useState(null);
    const [newsEndDateTemp, setNewsEndDateTemp] = useState(null);

    const [chartStartDate, setChartStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
    const [chartEndDate, setChartEndDate] = useState(new Date());
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isTableDropdownOpen, setIsTableDropdownOpen] = useState(false);
    const [isNewsDropdownOpen, setIsNewsDropdownOpen] = useState(false);
    const [isCompareDropdownOpen, setIsCompareDropdownOpen] = useState(false);
    const [newsCategory, setNewsCategory] = useState("All");
    const [authToken, setAuthToken] = useState(null);
    const [newsKeyword, setNewsKeyword] = useState(null);
    const [changeLocation, setChangeLocation] = useState(true);
    const country = "THAIL"

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const newsCategoryList = [
        "All",
        "Insurance",
        "Agriculture",
        "Climate",
        "Environment"
    ];
    const processTextToBullets = (text) => {
        // Split by lines
        const lines = text.split('. ').map(line => line.trim()).filter(line => line);

        let result = [];
        let currentList = null;

        lines.forEach((line, index) => {
            if (line.startsWith('Effect:') || line.startsWith('Cope & Suggestion:')) {
                if (currentList) {
                    result.push(<ul key={`list-${index}`}>{currentList}</ul>);
                    currentList = null;
                }
                result.push(<p key={`p-${index}`}>{line}</p>);
            } else if (line.startsWith('*')) {
                if (!currentList) currentList = [];
                currentList.push(<li key={`li-${index}`}>{line.slice(1).trim()}</li>);
            } else {
                if (currentList) {
                    result.push(<ul key={`list-${index}`}>{currentList}</ul>);
                    currentList = null;
                }
                result.push(<p key={`p-${index}`}>{line}</p>);
            }
        });

        if (currentList) {
            result.push(<ul key={`list-${lines.length}`}>{currentList}</ul>);
        }

        return result;
    };
    async function signInAsGuest() {
        try {
            await signIn({ username: 'guest_00001', password: 'r%3BGzbOO8Y~KI@%Ud#{' });

            let session = await fetchAuthSession();
            const authTokenTemp = session.tokens?.idToken?.toString();
            return { session, authTokenTemp };
        } catch (error) {
            console.error("Guest sign-in failed:", error);
            throw error;
        }
    }
    async function currentSession() {
        try {
            let session = await fetchAuthSession({ forceRefresh: true });
            let authTokenTemp = session.tokens?.idToken?.toString();
            let isSubscribeUser = false;

            // Check if the token is invalid or missing
            if (!authTokenTemp) {
                // Handle guest sign-in
                const guestSignInResult = await signInAsGuest();
                session = guestSignInResult.session;
                authTokenTemp = guestSignInResult.authTokenTemp;
                setIsUser(false);
            } else {
                const username = session.tokens.idToken.payload['cognito:username'];
                if (username !== "guest_00001") {
                    setIsUser(true);
                    setUsername(session.tokens.idToken.payload['name'].split(' ')[0]);
                } else {
                    setIsUser(false);
                }
            }

            // Check subscription status
            isSubscribeUser = session.tokens?.idToken?.payload['cognito:groups']?.includes('subscribe-user') || false;

            setIsSubscribeUser(isSubscribeUser);
            setAuthToken(authTokenTemp);

            // Process location data
            const lat = queryParams.get('lat');
            const lon = queryParams.get('lon');
            const city = queryParams.get('city');
            let latitudeStart = latitude;
            let longitudeStart = longitude;
            if (lat && lon) {
                latitudeStart = parseFloat(lat);
                longitudeStart = parseFloat(lon);

                setLatitude(latitudeStart);
                setLongitude(longitudeStart);
                setLatitudeTemp(latitudeStart);
                setLongitudeTemp(longitudeStart);

                if (city) {
                    const location = { value: city, label: city, lon: longitude, lat: latitude };
                    setSelectedCountry(location);
                    setSelectedCountryTemp(location);
                } else {
                    setSelectLocationType('Location');
                    setSelectLocationTypeTemp('Location');
                    setSelectedCountry(null);
                }
            } else {
                try {
                    navigator.geolocation.getCurrentPosition((position) => {
                        let latitudeStartTemp = parseFloat(position.coords.latitude.toFixed(6));
                        let longitudeStartTemp = parseFloat(position.coords.longitude.toFixed(6));
                        if (97 <= longitudeStartTemp <= 106 && 5 <= latitudeStartTemp <= 22) {
                            latitudeStart = latitudeStartTemp;
                            longitudeStart = longitudeStartTemp;
                            setLatitude(latitudeStartTemp);
                            setLatitudeTemp(latitudeStartTemp);
                            setLongitude(longitudeStartTemp);
                            setLongitudeTemp(longitudeStartTemp);
                            setSelectLocationType('Location');
                            setSelectLocationTypeTemp('Location');
                            setSelectedCountry(null);
                            setSelectedCountryTemp(null);
                        }
                    });
                } catch (error) {
                    console.log(error);
                }
            }

            setChartLoading(true);

            // Fetch news data
            // try {
            //     if (isSubscribeUser) {
            //         const news = await GetNews(authTokenTemp, null, country, null, null, null, null);
            //         setChangeLocation(false);
            //         setNewsLoading(false);
            //         setNews(news);
            //         setNewsAll(news);
            //     }
            // } catch (error) {
            //     console.log("error", error);
            //     if (error.response?.status === 401) {
            //         handleLogout();
            //     }
            // }

        GetLocationList().then((data) => {
            setCityList(data);
        });

            let historyData, predictData, climateData;
            if (isSubscribeUser) {
                [historyData, predictData] = await Promise.all([
                    GetHistoryData(latitudeStart, longitudeStart, authTokenTemp),
                    GetPredictData(latitudeStart, longitudeStart, authTokenTemp),
                    // GetClimatePredict(latitudeStart, longitudeStart, authTokenTemp),
                ]);
                
                setChangeLocation(false);
                // setClimateData(climateData);
                // setIsLoadingClimate(false);
            } else {
                historyData = await GetHistoryData(latitudeStart, longitudeStart, authTokenTemp);
                setChangeLocation(false);
                predictData = { features: [] };
            }

            // Process history data
            const tabledata = transformDataTable(historyData, predictData);
            const graphData = tabledata[selectedIndicatior.value][selectType.value];
            let bigLineChartOptionsTemp = { ...bigLineChartOptions };

            if (isSubscribeUser) {
                const xaxislineTemp = new Date(historyData.features[historyData.features.length - 1].properties.time.split('T')[0]).getTime();
                const predictLenghtTemp = predictData.features.length;
                setXaxisline(xaxislineTemp);
                setPredictLenght(predictLenghtTemp);
                bigLineChartOptionsTemp = {
                    ...bigLineChartOptions,
                    forecastDataPoints: { count: predictLenghtTemp },
                    annotations: {
                        ...bigLineChartOptions.annotations,
                        xaxis: [{ x: xaxislineTemp, strokeDashArray: 0, borderColor: '#775DD0' }],
                    },
                };
            }

            const data = [{ name: selectType.value, type: "line", data: graphData }];
            const data2 = [{ name: selectType.value, type: "area", data: tabledata.no_predict_data[selectedIndicatior.value][selectType.value] }];

            // Update state with the processed data
            setChartLoading(false);
            setChartState({
                ...chartState,
                series: data,
                options: bigLineChartOptionsTemp,
                seriesLine: data,
                optionsLine: smallLineChartOptions,
                miniChartSeries: data2,
                miniChartOptions: miniChartOptions,
            });
            setLatestDate(historyData.features[historyData.features.length - 1].properties.time);
            setTransformData({ [`${latitudeStart}_${longitudeStart}`]: tabledata });
            setTableData({ data: tabledata.table_data[selectType.value], cols: cols_other });
        } catch (error) {
            console.error('Error fetching JWT token:', error);
            // await handleLogout();
        }
    }

    useEffect(() => {
        currentSession();
        // Promise.all([currentSession(), getArticleList(), getJobPosting()]);
    }, []);

    const enableArticle = useSelector((state) => state.article.enable);
    useEffect(() => {
        if (enableArticle) getArticleList();
    }, [enableArticle]);

    const enableDataLabeling = useSelector((state) => state.dataLabeling.enable);
    useEffect(() => {
        if (enableDataLabeling) getJobPosting();
    }, [enableDataLabeling])

    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.has('tab'))
            setPage(searchParams.get('tab'))
    }, [searchParams])

    
    async function getArticleList() {
        const enableArticle = true;
        if (enableArticle) {
            const mock = false;
            if (mock) {
                const res = {
                    "1/metadata.md": "# บทนำสู่ Machine Learning (Intro to Machine Learning)\n## เป็นการแนะนำพื้นฐานของ Machine Learning  หัวข้อที่ครอบคลุม ได้แก่ ประเภทของ Machine Learning  อัลกอริทึมที่ใช้กันทั่วไป  การประเมินโมเดล และแอปพลิเคชันของ Machine Learning\n### Level: Beginner\n### Authors: Potomac team",
                    "2/metadata.md": "# การแข่งขัน Kaggle เกี่ยวกับความต้องการใช้จักรยานแชร์ (Kaggle competition on bike sharing demand)\n## การแข่งขันวิเคราะห์ข้อมูลบนแพลตฟอร์ม Kaggle ผู้เข้าแข่งขันต้องใช้โมเดล Machine Learning เพื่อคาดการณ์ความต้องการใช้จักรยานแชร์ในสถานที่และช่วงเวลาต่างๆ การแข่งขันเหล่านี้ช่วยส่งเสริมการพัฒนาโมเดล Machine Learning ใหม่ๆ\n### Level: Beginner\n### Authors: Potomac team\n",
                    "3/metadata.md": "# การคาดการณ์อุณหภูมิด้วยการวิเคราะห์ลำดับเวลา (Forecasting Temperature with Time Series Analysis)\n## ใช้เทคนิคทางสถิติเพื่อคาดการณ์อุณหภูมิในอนาคต โดยใช้ข้อมูลอุณหภูมิในอดีต เทคนิคที่นิยมใช้ ได้แก่ การถดถอยแบบเส้นตรง (Linear Regression) และ ARIMA\n### Level: Beginner\n### Authors: Potomac team\n\n\n"
                };
                if (res) {
                    const articles = [];
                    Object.keys(res).forEach((key) => {
                    const text = res[key];
                    const data = text.split("#").filter((item) => item !== "");
                    const id = key.replace("/", "").replace("metadata.md", "");
                    const title = data[0];
                    const description = data[1];
                    const level = data[2];
                    const authorName = data[3];
                    const article = {
                        id: id,
                        title: title,
                        description: description,
                        level: level,
                        authorName: authorName,
                    };
                    articles.push(article);
                    });
                    dispatch(setArticles(articles));
                } else {
                    console.error("Error fetching article");
                    dispatch(resetArticles());
                }
            } else {
                setLoadingArticles(true);
                const res = await Services.Article.get();
                if (res) {
                    const articles = [];
                    Object.keys(res).forEach((key) => {
                    const text = res[key];
                    const data = text.split("#").filter((item) => item !== "");
                    const id = key.replace("/", "").replace("metadata.md", "");
                    const title = data[0];
                    const description = data[1];
                    const level = data[2];
                    const authorName = data[3];
                    const article = {
                        id: id,
                        title: title,
                        description: description,
                        level: level,
                        authorName: authorName,
                    };
                    articles.push(article);
                    });
                    dispatch(setArticles(articles));
                } else {
                    console.error("Error fetching article");
                    dispatch(resetArticles());
                }
                setLoadingArticles(false);
            }
        }
    }

    
    async function getJobPosting() {
        console.log("enableDataLabeling", enableDataLabeling);
        if (enableDataLabeling) {
            const res = await Services.DataLabeling.JobPosting.get();
            if (res && res.Items) {
                dispatch(setJobList(res.Items));
            }
        }
    }

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    const updateData = (timeline) => {
        setSelection(timeline);
        let currentDate = new Date();
        switch (timeline) {
            case '1M':
                currentDate.setMonth(currentDate.getMonth() - 1);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '3M':
                currentDate.setMonth(currentDate.getMonth() - 3);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '6M':
                currentDate.setMonth(currentDate.getMonth() - 6);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '1Y':
                currentDate.setFullYear(currentDate.getFullYear() - 1);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '3Y':
                currentDate.setFullYear(currentDate.getFullYear() - 3);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '5Y':
                currentDate.setFullYear(currentDate.getFullYear() - 5);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case 'YTD':
                currentDate.setMonth(0);
                currentDate.setDate(1);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case 'All':
                currentDate = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value][0].x;
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            default:
                break;
        }
    }


    const handleLatitudeChange = (event) => {
        setLatitudeTemp(event.target.value);
    };

    const handleCompareLatitudeChange = (event) => {
        setCompareLatitude(event.target.value);
    };

    const handleLongitudeChange = (event) => {
        setLongitudeTemp(event.target.value);
    };

    const handleCompareLongitudeChange = (event) => {
        setCompareLongitude(event.target.value);
    };

    const handleCountryChange = (selectedOption) => {
        console.log(selectedOption);
        setSelectedCountryTemp(selectedOption);
        setLatitudeTemp(selectedOption.value.Latitude);
        setLongitudeTemp(selectedOption.value.Longitude);
    };

    const handleCompareCountryChange = (selectedOption) => {
        console.log(selectedOption);
        setSelectedCompareCountry(selectedOption);
        setCompareLatitude(selectedOption.value.Latitude);
        setCompareLongitude(selectedOption.value.Longitude);
    };

    const handleLocationSearch = async () => {
        if (latitudeTemp === latitude && longitudeTemp === longitude) {
            console.log('Please enter different latitude and longitude');
            return;
        }
        setChangeLocation(true);
        setChartLoading(true);
        setSelectLocationType(selectLocationTypeTemp);
        setSelectedCountry(selectedCountryTemp);
        setLatitude(latitudeTemp);
        setLongitude(longitudeTemp);
        const key = `${latitudeTemp}_${longitudeTemp}`;
        if (key in transformData) {
            const mapData = transformData[key][selectedIndicatior.value][selectType.value];
            let data = [{ name: selectType.value, type: "line", data: mapData }]
            let data2 = [{ name: selectType.value, type: "area", data: mapData }]
            let bigLineChartOptionsTemp = { ...bigLineChartOptions }
            bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit }, seriesName: [selectType.label] }];
            bigLineChartOptionsTemp.colors = [selectType.color];
            if (selectType.value !== "precip" && selectType.value !== "accum_rain_rate") {
                bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                bigLineChartOptionsTemp.annotations.xaxis = [{
                    x: xaxisline,
                    strokeDashArray: 0,
                    borderColor: '#775DD0'
                }];
            }
            let smallLineChartOptionsTemp = { ...smallLineChartOptions };
            smallLineChartOptionsTemp.colors = [selectType.color];
            smallLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectType.color, downward: selectType.color };
            smallLineChartOptionsTemp.chart.selection.xaxis.min = chartStartDate.getTime();
            smallLineChartOptionsTemp.chart.selection.xaxis.max = chartEndDate.getTime();
            setChartState({ ...chartState, series: data, options: bigLineChartOptionsTemp, seriesLine: data, optionsLine: smallLineChartOptionsTemp, miniChartSeries: data2 });
            setTableData({ data: transformData[key]['table_data'][selectType.value], cols: selectType.value === 'precip' ? cols_rain_rate : cols_other });
            setChartLoading(false);

        } else {
            let historyData, predictData, climateData;
            if (isSubscribeUser) {
                // setIsLoadingClimate(true);
                [historyData, predictData] = await Promise.all([
                    GetHistoryData(latitude, longitude, authToken),
                    GetPredictData(latitude, longitude, authToken),
                    // GetClimatePredict(latitude, longitude, authToken),
                ]);
                // setClimateData(climateData);
                // setIsLoadingClimate(false);
            } else {
                [historyData] = await Promise.all([
                    GetHistoryData(latitude, longitude, authToken),
                ]);
                predictData = { features: [] };
            }

            // Process history data
            const data = transformDataTable(historyData, predictData);
            const mapData = data[selectedIndicatior.value][selectType.value];
            let data1 = [{ name: selectType.value, type: "line", data: mapData }]
            let data2 = [{ name: selectType.value, type: "area", data: data.no_predict_data[selectedIndicatior.value][selectType.value] }]
            let bigLineChartOptionsTemp = { ...bigLineChartOptions }
            bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit }, seriesName: [selectType.label] }];
            bigLineChartOptionsTemp.colors = [selectType.color];
            if (selectType.value !== "precip" && selectType.value !== "accum_rain_rate") {
                bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                bigLineChartOptionsTemp.annotations.xaxis = [{
                    x: xaxisline,
                    strokeDashArray: 0,
                    borderColor: '#775DD0'
                }];
            }
            let smallLineChartOptionsTemp = { ...smallLineChartOptions };
            smallLineChartOptionsTemp.colors = [selectType.color];
            smallLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectType.color, downward: selectType.color };
            smallLineChartOptionsTemp.chart.selection.xaxis.min = chartStartDate.getTime();
            smallLineChartOptionsTemp.chart.selection.xaxis.max = chartEndDate.getTime();
            setChartState({ ...chartState, series: data1, options: bigLineChartOptionsTemp, seriesLine: data1, optionsLine: smallLineChartOptionsTemp, miniChartSeries: data2 });
            setTransformData({ [key]: data });
            setTableData({ data: data.table_data[selectType.value], cols: selectType.value === 'precip' ? cols_rain_rate : cols_other });
            setCompareLocationList([]);
            setSelectedCompareTypeOptions([]);
            setChartLoading(false);
        }
        setChangeLocation(false);
    }

    const handleIndicatiorChange = (selectedOption) => {
        if (selectedOption.value !== selectedIndicatior.value) {
            if (selectedOption.value === 'min_max') {
                const transformedMaxData = [{ name: selectType.label, type: "candlestick", data: transformData[`${latitude}_${longitude}`][selectedOption.value][selectType.value] }];
                const transformedMaxData2 = [{ name: selectType.label, type: "candlestick", data: transformData[`${latitude}_${longitude}`].no_predict_data[selectedOption.value][selectType.value] }];
                let bigLineChartOptionsTemp = { ...bigLineChartOptions };
                bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit }, seriesName: [selectType.label], }];

                bigLineChartOptionsTemp.colors = [selectType.color];
                bigLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectType.color, downward: selectType.color };

                if (isSubscribeUser) {
                    if (selectedOption.value !== "precip" && selectedOption.value !== "accum_rain_rate") {
                        bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                        bigLineChartOptionsTemp.annotations.xaxis = [{
                            x: xaxisline,
                            strokeDashArray: 0,
                            borderColor: '#775DD0'
                        }];
                    }
                }
                let smallLineChartOptionsTemp = { ...smallLineChartOptions };
                smallLineChartOptionsTemp.colors = [selectType.color];
                smallLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectType.color, downward: selectType.color };
                smallLineChartOptionsTemp.chart.selection.xaxis.min = chartStartDate.getTime();
                smallLineChartOptionsTemp.chart.selection.xaxis.max = chartEndDate.getTime();
                setChartState({ ...chartState, series: transformedMaxData, options: bigLineChartOptionsTemp, seriesLine: transformedMaxData, optionsLine: smallLineChartOptionsTemp, miniChartSeries: transformedMaxData2 });
            } else {
                const transformedMaxData = [{ name: selectType.label, type: "line", data: transformData[`${latitude}_${longitude}`][selectedOption.value][selectType.value] }];
                const transformedMaxData2 = [{ name: selectType.label, type: "area", data: transformData[`${latitude}_${longitude}`].no_predict_data[selectedOption.value][selectType.value] }];
                let bigLineChartOptionsTemp = { ...bigLineChartOptions };
                bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit }, seriesName: [selectType.label] }];
                bigLineChartOptionsTemp.colors = [selectType.color];
                bigLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectType.color, downward: selectType.color };

                if (isSubscribeUser) {
                    if (selectedOption.value !== "precip" && selectedOption.value !== "accum_rain_rate") {
                        bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                        bigLineChartOptionsTemp.annotations.xaxis = [{
                            x: xaxisline,
                            strokeDashArray: 0,
                            borderColor: '#775DD0'
                        }];
                    }
                }
                let smallLineChartOptionsTemp = { ...smallLineChartOptions };
                smallLineChartOptionsTemp.colors = [selectType.color];
                smallLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectType.color, downward: selectType.color };
                smallLineChartOptionsTemp.chart.selection.xaxis.min = chartStartDate.getTime();
                smallLineChartOptionsTemp.chart.selection.xaxis.max = chartEndDate.getTime();
                setChartState({ ...chartState, series: transformedMaxData, options: bigLineChartOptionsTemp, seriesLine: transformedMaxData, optionsLine: smallLineChartOptionsTemp, miniChartSeries: transformedMaxData2 });
            }
            setSelectedCompareTypeOptions([]);
            setCompareLocationList([]);
            setSelectedIndicatior(selectedOption);
        }
    };

    const handleTypeChange = (selectedOption) => {
        if (selectedOption.value !== selectType.value) {
            let chartType = "line"
            let chartType2 = "area"
            let selectedIndicatiorValue = selectedIndicatior.value
            if (selectedOption.value === "precip") {
                selectedIndicatiorValue = 'rain_rate'
                setIndicatiorOption([{ value: 'rain_rate', label: 'Rain Rate' }]);
                setSelectedIndicatior({ value: 'rain_rate', label: 'Rain Rate' });
                setTableData({ data: transformData[`${latitude}_${longitude}`]['table_data'][selectedOption.value], cols: cols_rain_rate });
            } else if (selectedOption.value === "accum_rain_rate") {
                selectedIndicatiorValue = 'accum_rain_rate'
                setIndicatiorOption([{ value: 'accum_rain_rate', label: 'Annual Accumulated Rain Rate' }]);
                setSelectedIndicatior({ value: 'accum_rain_rate', label: 'Annual Accumulated Rain Rate' });
                setTableData({ data: transformData[`${latitude}_${longitude}`]['table_data']['precip'], cols: cols_rain_rate });
            } else if (selectedOption.value === "min_max") {
                chartType = "candlestick";
                chartType2 = "candlestick";
                setTableData({ data: transformData[`${latitude}_${longitude}`]['table_data'][selectedOption.value], cols: cols_other });
            } else if (selectType.value === "precip" || selectType.value === "accum_rain_rate") {
                selectedIndicatiorValue = 'median'
                setSelectedIndicatior({ value: 'median', label: 'Median' });
                setIndicatiorOption([
                    { value: 'max', label: 'Maximum' },
                    { value: 'min', label: 'Minimum' },
                    // { value: 'min_max', label: 'Minimum-Maximum' },
                    { value: 'mean', label: 'Mean' },
                    { value: 'median', label: 'Median' }
                ]);
                setTableData({ data: transformData[`${latitude}_${longitude}`]['table_data'][selectedOption.value], cols: cols_other });
            } else {
                setTableData({ data: transformData[`${latitude}_${longitude}`]['table_data'][selectedOption.value], cols: cols_other });
            }
            const transformedMaxData = [{ name: selectedOption.label, type: chartType, data: transformData[`${latitude}_${longitude}`][selectedIndicatiorValue][selectedOption.value] }];
            const transformedMaxData2 = [{ name: selectedOption.label, type: chartType2, data: transformData[`${latitude}_${longitude}`].no_predict_data[selectedIndicatiorValue][selectedOption.value] }];
            let bigLineChartOptionsTemp = { ...bigLineChartOptions };
            bigLineChartOptionsTemp['colors'] = [selectedOption.color];
            bigLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectedOption.color, downward: selectedOption.color };
            bigLineChartOptionsTemp.yaxis = [{ title: { text: selectedOption.unit }, seriesName: [selectedOption.label] }];
            let smallLineChartOptionsTemp = { ...smallLineChartOptions };
            smallLineChartOptionsTemp.colors = [selectedOption.color];
            smallLineChartOptionsTemp.plotOptions.candlestick.colors = { upward: selectedOption.color, downward: selectedOption.color };
            smallLineChartOptionsTemp.chart.selection.xaxis.min = chartStartDate.getTime();
            smallLineChartOptionsTemp.chart.selection.xaxis.max = chartEndDate.getTime();
            let miniChartOptionsTemp = { ...miniChartOptions };
            if (selectedOption.value === "heatidx") {
                miniChartOptionsTemp.annotations = heatIndexAnnotation;
                bigLineChartOptionsTemp.annotations = heatIndexAnnotation;
                if (isSubscribeUser) {
                    bigLineChartOptionsTemp.annotations.xaxis = [{
                        x: xaxisline,
                        strokeDashArray: 0,
                        borderColor: '#775DD0'
                    }];
                    bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                }
            } else {
                miniChartOptionsTemp.annotations = { yaxis: [] }
                bigLineChartOptionsTemp.annotations = { yaxis: [], xaxis: [] }
                if (isSubscribeUser) {
                    if (selectedOption.value !== "precip" && selectedOption.value !== "accum_rain_rate") {
                        bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                        bigLineChartOptionsTemp.annotations.xaxis = [{
                            x: xaxisline,
                            strokeDashArray: 0,
                            borderColor: '#775DD0'
                        }];
                    }
                }
            }

            setChartState({ ...chartState, series: transformedMaxData, options: bigLineChartOptionsTemp, seriesLine: transformedMaxData, optionsLine: smallLineChartOptionsTemp, miniChartSeries: transformedMaxData2, miniChartOptions: miniChartOptionsTemp });
            let selectedOptionValues = typeOptions.filter((option) => selectedOption.value !== option.value);
            setSelectType(selectedOption);
            setCompareTypeOption(selectedOptionValues);
            setSelectedCompareTypeOptions([]);
            setCompareLocationList([])
        }
    };

    const handleCompareTypeChange = (option) => {
        if (compareLocationList.length === 0 && typeof chartState.series[0].name !== 'number') {
            const index = selectedCompareTypeOptions.indexOf(option);
            if (index > -1) {
                setSelectedCompareTypeOptions(selectedCompareTypeOptions.filter(item => item !== option));
                const transformedMaxData = chartState.series.filter((data) => data.name !== option.label);
                let chartStateYAxisTemp = chartState.options.yaxis;
                let chartStateColorTemp = chartState.options.colors;
                chartStateColorTemp = chartStateColorTemp.filter(item => item !== option.color);

                let index = chartStateYAxisTemp.findIndex((item) => item.title && item.title.text === option.unit)
                if (chartStateYAxisTemp[index].seriesName.length === 1) {
                    chartStateYAxisTemp.splice(index, 1);
                } else {
                    chartStateYAxisTemp[index].seriesName = chartStateYAxisTemp[index].seriesName.filter(item => item !== option.label);
                }
                setChartState(prevState => ({
                    ...prevState,
                    series: transformedMaxData,
                    options: {
                        ...prevState.options,
                        yaxis: chartStateYAxisTemp,
                        colors: chartStateColorTemp,
                        xaxis: { ...prevState.miniChartOptions.xaxis, min: chartStartDate.getTime(), max: chartEndDate.getTime() }
                    },
                    optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() } } } }
                }));
            } else {
                setSelectedCompareTypeOptions([...selectedCompareTypeOptions, option]);
                let transformedMaxData;
                if (selectType.value === 'precip' || selectType.value === 'accum_rain_rate') {
                    if (option.value === 'precip') {
                        transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`]['rain_rate'][option.value] }
                    } else if (option.value === 'accum_rain_rate') {
                        transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`]['accum_rain_rate'][option.value] }
                    } else if (selectedIndicatior.value === 'rain_rate' || selectedIndicatior.value === 'accum_rain_rate' || selectedIndicatior.value === 'min_max') {
                        let cutData = structuredClone(transformData[`${latitude}_${longitude}`].no_predict_data['max'][option.value]);
                        cutData = cutData.filter((item) => item.x >= chartState.series[0].data[0].x && item.x <= chartState.series[0].data[chartState.series[0].data.length - 1].x);
                        transformedMaxData = { name: option.label, type: "line", data: cutData }
                    } else {
                        let cutData = structuredClone(transformData[`${latitude}_${longitude}`].no_predict_data[selectedIndicatior.value][option.value]);
                        cutData = cutData.filter((item) => item.x >= chartState.series[0].data[0].x && item.x <= chartState.series[0].data[chartState.series[0].data.length - 1].x);
                        transformedMaxData = { name: option.label, type: "line", data: cutData }
                    }
                } else {
                    if (option.value === 'precip') {
                        transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`]['rain_rate'][option.value] }
                    } else if (option.value === 'accum_rain_rate') {
                        transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`]['accum_rain_rate'][option.value] }
                    } else if (selectedIndicatior.value === 'rain_rate' || selectedIndicatior.value === 'accum_rain_rate' || selectedIndicatior.value === 'min_max') {
                        let cutData = structuredClone(transformData[`${latitude}_${longitude}`]['max'][option.value]);
                        cutData = cutData.filter((item) => item.x >= chartState.series[0].data[0].x && item.x <= chartState.series[0].data[chartState.series[0].data.length - 1].x);
                        transformedMaxData = { name: option.label, type: "line", data: cutData }
                    } else {
                        let cutData = structuredClone(transformData[`${latitude}_${longitude}`][selectedIndicatior.value][option.value]);
                        cutData = cutData.filter((item) => item.x >= chartState.series[0].data[0].x && item.x <= chartState.series[0].data[chartState.series[0].data.length - 1].x);
                        transformedMaxData = { name: option.label, type: "line", data: cutData }
                    }

                }
                let chartStateYAxisTemp = chartState.options.yaxis;
                let index = chartStateYAxisTemp.findIndex((item) => item.title && item.title.text === option.unit);
                if (index === -1) {
                    chartStateYAxisTemp.push({
                        // title: { text: option.unit }, seriesName: [option.label],  opposite: true
                        title: { text: option.unit }, seriesName: [option.label]
                    })
                } else {
                    chartStateYAxisTemp[index].seriesName.push(option.label)
                }
                setChartState(prevState => ({
                    ...prevState,
                    series: [...prevState.series, transformedMaxData],
                    options: {
                        ...prevState.options,
                        yaxis: chartStateYAxisTemp,
                        colors: [...prevState.options.colors, option.color],
                        xaxis: { ...prevState.miniChartOptions.xaxis, min: chartStartDate.getTime(), max: chartEndDate.getTime() }
                    },
                    optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() } } } }
                }));
            }
        } else {
            setCompareLocationList([]);
            setSelectedCompareTypeOptions([...selectedCompareTypeOptions, option]);
            let transformedMaxData;

            if (selectType.value === 'precip' || selectType.value === 'accum_rain_rate') {
                if (selectedIndicatior.value === 'rain_rate' || selectedIndicatior.value === 'accum_rain_rate' || selectedIndicatior.value === 'min_max') {
                    transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`].no_predict_data['max'][option.value] }
                } else if (option.value === 'precip') {
                    transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`].no_predict_data['rain_rate'][option.value] }
                } else if (option.value === 'accum_rain_rate') {
                    transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`].no_predict_data['accum_rain_rate'][option.value] }
                } else {
                    transformedMaxData = { name: option.label, type: selectedIndicatior.value === "min_max" ? "candlestick" : "line", data: transformData[`${latitude}_${longitude}`].no_predict_data[selectedIndicatior.value][option.value] }
                }
            } else {
                if (selectedIndicatior.value === 'rain_rate' || selectedIndicatior.value === 'accum_rain_rate' || selectedIndicatior.value === 'min_max') {
                    transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`]['max'][option.value] }
                } else if (option.value === 'precip') {
                    transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`]['rain_rate'][option.value] }
                } else if (option.value === 'accum_rain_rate') {
                    transformedMaxData = { name: option.label, type: "line", data: transformData[`${latitude}_${longitude}`]['accum_rain_rate'][option.value] }
                } else {
                    transformedMaxData = { name: option.label, type: selectedIndicatior.value === "min_max" ? "candlestick" : "line", data: transformData[`${latitude}_${longitude}`][selectedIndicatior.value][option.value] }
                }
            }
            let chartStateYAxisTemp = [chartState.options.yaxis[0]];
            chartStateYAxisTemp[0].seriesName = [selectedIndicatior.label];
            let firstData = chartState.series[0]
            let minTemp = chartState.optionsLine.chart.selection.xaxis.min;
            let maxTemp = chartState.optionsLine.chart.selection.xaxis.max;

            if (typeof firstData.name === 'number') {
                firstData.data = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value];
                minTemp = chartStartDate.getTime();
                maxTemp = chartEndDate.getTime();
            }
            firstData.name = selectedIndicatior.label;
            let index = chartStateYAxisTemp.findIndex((item) => item.title && item.title.text === option.unit);
            if (index === -1) {
                chartStateYAxisTemp.push({
                    // seriesName: [option.label], title: { text: option.unit }, opposite: true,
                    seriesName: [option.label], title: { text: option.unit },
                })
            } else {
                chartStateYAxisTemp[index].seriesName.push(option.label)
            }
            let forecastData = { count: 0 }
            let annotationsXaxis = []
            if (isSubscribeUser) {
                if (selectedIndicatior.value !== "precip" && selectedIndicatior.value !== "accum_rain_rate") {
                    forecastData = { count: predictLenght }
                    annotationsXaxis = [{
                        x: xaxisline,
                        strokeDashArray: 0,
                        borderColor: '#775DD0'
                    }];
                }
            }
            setChartState(prevState => ({
                ...prevState,
                series: [firstData, transformedMaxData],
                seriesLine: [firstData],
                options: {
                    ...prevState.options,
                    yaxis: chartStateYAxisTemp,
                    colors: [prevState.options.colors[0], option.color],
                    forecastDataPoints: forecastData,
                    annotations: { ...prevState.options.annotations, xaxis: annotationsXaxis }
                },
                optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: minTemp, max: maxTemp } } } }
            }));

        }
    };

    const handleAddCompareLocation = async () => {
        setIsCompareDropdownOpen(false);
        setSelectedCompareTypeOptions([]);
        if (!compareLatitude || !compareLongitude) {
            console.log('Please enter latitude and longitude');
            return;
        }
        let location = {};
        if (selectCompareLocationType === 'Location') {
            location = { type: 'Location', lat: compareLatitude, lon: compareLongitude }
        } else {
            location = { type: 'Country', location: selectedCompareCountry.value.Province, lat: selectedCompareCountry.value.Latitude, lon: selectedCompareCountry.value.Longitude }
        }
        const foundIndex = compareLocationList.findIndex(item => (
            item.lat === location.lat && item.lon === location.lon
        ));

        if (foundIndex === -1 && latitude !== location.lat && longitude !== location.lon) {
            setChartLoading(true);
            const key = `${location.lat}_${location.lon}`;
            if (key in transformData) {
                const data = transformData[key];
                if (compareLocationList.length === 0) {

                    let bigLineChartOptionsTemp = { ...bigLineChartOptions };
                    bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit } }];
                    bigLineChartOptionsTemp.colors = [bigLineChartOptionsTemp.colors[0], getRandomColor()];
                    if (selectType.value === "heatidx") {
                        bigLineChartOptionsTemp.annotations = heatIndexAnnotation;

                    } else {
                        bigLineChartOptionsTemp.annotations.yaxis = []
                    }
                    if (isSubscribeUser) {
                        if (selectType.value !== "precip" && selectType.value !== "accum_rain_rate") {
                            bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                            bigLineChartOptionsTemp.annotations.xaxis = [{
                                x: xaxisline,
                                strokeDashArray: 0,
                                borderColor: '#775DD0'
                            }];
                        }
                    }

                    let firstData = chartState.series[0];
                    let minTemp = chartState.optionsLine.chart.selection.xaxis.min;
                    let maxTemp = chartState.optionsLine.chart.selection.xaxis.max;
                    if (typeof firstData.name === 'number') {
                        firstData.data = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value];
                        minTemp = chartStartDate.getTime();
                        maxTemp = chartEndDate.getTime();
                    }
                    firstData.name = selectLocationType === 'Country' ? selectedCountry.value : `(${latitude},${longitude})`;
                    if (selectedIndicatior.value === 'min_max') {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data['max'][selectType.value] };
                        setChartState(prevState => ({ ...prevState, series: [firstData, newData], options: bigLineChartOptionsTemp, seriesLine: [firstData], optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { enabled: true, xaxis: { min: minTemp, max: maxTemp } } } } }));
                        setChartLoading(false);
                    } else {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data[selectedIndicatior.value][selectType.value] };
                        setChartState(prevState => ({ ...prevState, series: [firstData, newData], options: bigLineChartOptionsTemp, seriesLine: [firstData], optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { enabled: true, xaxis: { min: minTemp, max: maxTemp } } } } }));
                        setChartLoading(false);
                    }
                } else {
                    if (selectedIndicatior.value === 'min_max') {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data['max'][selectType.value] };
                        setChartState(prevState => ({ ...prevState, series: [...prevState.series, newData], options: { ...prevState.options, colors: [...prevState.options.colors, getRandomColor()] }, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { enabled: true, xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() } } } } }));
                        setChartLoading(false);
                    } else {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data[selectedIndicatior.value][selectType.value] };
                        setChartState(prevState => ({ ...prevState, series: [...prevState.series, newData], options: { ...prevState.options, colors: [...prevState.options.colors, getRandomColor()] }, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { enabled: true, xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() } } } } }));
                        setChartLoading(false);
                    }
                }
            } else {
                setChartLoading(true);

                let historyData, predictData;
                if (isSubscribeUser) {
                    setIsLoadingClimate(true);
                    [historyData, predictData] = await Promise.all([
                        GetHistoryData(location.lat, location.lon, authToken),
                        GetPredictData(location.lat, location.lon, authToken)
                    ]);
                } else {
                    [historyData] = await Promise.all([
                        GetHistoryData(latitude, longitude, authToken),
                    ]);
                    predictData = { features: [] };
                }



                const data = transformDataTable(historyData, predictData);
                setTransformData({ ...transformData, [key]: data });

                if (compareLocationList.length === 0) {
                    let bigLineChartOptionsTemp = { ...bigLineChartOptions };
                    bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit } }];
                    bigLineChartOptionsTemp.colors = [bigLineChartOptionsTemp.colors[0], getRandomColor()];

                    if (selectType.value === "heatidx") {
                        bigLineChartOptionsTemp.annotations = heatIndexAnnotation;

                    } else {
                        bigLineChartOptionsTemp.annotations.yaxis = []
                    }
                    if (isSubscribeUser) {
                        if (selectType.value !== "precip" && selectType.value !== "accum_rain_rate") {
                            bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                            bigLineChartOptionsTemp.annotations.xaxis = [{
                                x: xaxisline,
                                strokeDashArray: 0,
                                borderColor: '#775DD0'
                            }];
                        }
                    }
                    let firstData = chartState.series[0]
                    let minTemp = chartState.optionsLine.chart.selection.xaxis.min;
                    let maxTemp = chartState.optionsLine.chart.selection.xaxis.max;
                    if (typeof firstData.name === 'number') {
                        firstData.data = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value];
                        minTemp = chartStartDate.getTime();
                        maxTemp = chartEndDate.getTime();
                    }
                    bigLineChartOptionsTemp.xaxis.min = minTemp;
                    bigLineChartOptionsTemp.xaxis.max = maxTemp;
                    firstData.name = selectLocationType === 'Country' ? selectedCountry.value : `(${latitude},${longitude})`;
                    if (selectedIndicatior.value === 'min_max') {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data['max'][selectType.value] };
                        setChartState(prevState => ({
                            ...prevState, series: [firstData, newData], options: bigLineChartOptionsTemp, seriesLine: [firstData],
                            optionsLine: {
                                ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: minTemp, max: maxTemp } } },
                            }
                        }));
                        setChartLoading(false);
                    } else {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data[selectedIndicatior.value][selectType.value] };
                        setChartState(prevState => ({
                            ...prevState, series: [firstData, newData], options: bigLineChartOptionsTemp, seriesLine: [firstData],
                            optionsLine: {
                                ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: minTemp, max: maxTemp } } },
                            }
                        }));
                        setChartLoading(false);
                    }
                } else {
                    if (selectedIndicatior.value === 'min_max') {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data['max'][selectType.value] };
                        setChartState(prevState => ({ ...prevState, series: [...prevState.series, newData], options: { ...prevState.options, colors: [...prevState.options.colors, getRandomColor()] } }));
                        setChartLoading(false);
                    } else {
                        let newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data[selectedIndicatior.value][selectType.value] };
                        setChartState(prevState => ({ ...prevState, series: [...prevState.series, newData], options: { ...prevState.options, colors: [...prevState.options.colors, getRandomColor()] } }));
                        setChartLoading(false);
                    }
                }
            }
            setCompareLocationList([...compareLocationList, location]);
        } else {
            console.log('Location already exists in the list');
        }
    }

    const handleYearChange = (option) => {
        if (yearList.includes(option)) {
            setYearList(yearList.filter(year => year !== option))
        } else {
            setYearList([...yearList, option])
        }
    }

    const handleHistoryCompare = () => {
        if (yearList.length === 0) {
            console.log('Please select compare year');
        } else {
            setIsCompareDropdownOpen(false);
            let tempSeriesData = [];
            let tempData = [...transformData[`${latitude}_${longitude}`].no_predict_data[selectedIndicatior.value][selectType.value]];
            let chartStateYAxisTemp = chartState.options.yaxis;
            let chartStateColorTemp = chartState.options.colors;
            chartStateColorTemp = [chartStateColorTemp[0]];
            chartStateYAxisTemp = [chartStateYAxisTemp[0]];
            for (let year of yearList) {
                chartStateColorTemp.push(getRandomColor());
                const dataInYear = tempData.filter(item => {
                    return item.x.getFullYear() === year;
                });

                const seriesDataForYear = {
                    name: year,
                    //  type: "line",
                    data: dataInYear.map(item => [new Date(2024, item.x.getMonth(), item.x.getDate()).getTime(), item.y])
                };
                tempSeriesData.push(seriesDataForYear);
            }
            setChartState(prevState => ({
                ...prevState,
                series: tempSeriesData,
                seriesLine: [tempSeriesData[0]],
                options: {
                    ...prevState.options,
                    yaxis: chartStateYAxisTemp,
                    colors: chartStateColorTemp,
                    forecastDataPoints: { count: 0 },
                    annotations: { ...prevState.options.annotations, xaxis: [] }
                },
                optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: new Date('2024-01-01').getTime(), max: new Date('2024-12-31').getTime() } } } }
            }));
            setCompareLocationList([])
            setSelectedCompareTypeOptions([]);
        }
    }

    const exportColumns = tableData.cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, tableData.data);
                doc.save(`${selectType.value}_at_${latitude}_${longitude}.pdf`);
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(tableData.data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, `${selectType.value}_at_${latitude}_${longitude}`);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const changetimestamptodatePattern = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            // weekday: 'short',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            // second: 'numeric',
            hour12: false
        });
    }
    function timeAgo(date) {
        const currentDate = new Date();
        const diff = currentDate - date;
        const minutes = Math.floor(diff / 60000);

        if (minutes < 1) {
            return 'just now';
        } else if (minutes === 1) {
            return '1 minute ago';
        } else if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (minutes < 120) {
            return '1 hour ago';
        } else if (minutes < 1440) {
            return `${Math.floor(minutes / 60)} hours ago`;
        } else if (minutes < 2880) {// 2 * 24 * 60
            return '1 day ago';
        } else if (minutes < 10080) {
            return `${Math.floor(minutes / 1440)} days ago`;
        } else if (minutes < 20160) { // 14 * 24 * 60
            return '1 week ago';
        } else if (minutes < 525600) { // 365 * 24 * 60
            return `${Math.floor(minutes / 10080)} weeks ago`;
        } else {
            return `${Math.floor(minutes / 525600)} years ago`;
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const updateChartData = (timeline) => {
        setSelectionChart(timeline);
        let currentDate = new Date();
        setChartEndDate(new Date());
        switch (timeline) {
            case '1M':
                currentDate.setMonth(currentDate.getMonth() - 1);
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            case '3M':
                currentDate.setMonth(currentDate.getMonth() - 3);
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            case '6M':
                currentDate.setMonth(currentDate.getMonth() - 6);
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            case '1Y':
                currentDate.setFullYear(currentDate.getFullYear() - 1);
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            case '3Y':
                currentDate.setFullYear(currentDate.getFullYear() - 3);
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            case '5Y':
                currentDate.setFullYear(currentDate.getFullYear() - 5);
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            case 'YTD':
                currentDate.setMonth(0);
                currentDate.setDate(1);
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            case 'All':
                currentDate = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value][0].x;
                setChartStartDate(currentDate);
                setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: currentDate.getTime(), max: new Date().getTime() } } } } }));
                break
            default:
                break;
        }
    }

    const handleChartDateRangeSearch = () => {
        setIsDropdownOpen(false);
        setChartState(prevState => ({ ...prevState, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() } } } } }));

    }

    const handleCancelClick = () => {
        setIsDropdownOpen(false);
    };

    const handleTableDateRangeSearch = () => {
        const tableData = transformData[`${latitude}_${longitude}`]['table_data'][selectType.value].filter(item => item.date >= tableStartDate.toISOString().split('T')[0] && item.date <= tableEndDate.toISOString().split('T')[0]);
        setTableData({ data: tableData, cols: selectType.value === 'precip' ? cols_rain_rate : cols_other });
        setIsTableDropdownOpen(false);
    }
    const handleTableDateRangeCancel = () => {
        setIsTableDropdownOpen(false);
    };

    const updateTableData = (timeline) => {
        setSelectionTable(timeline);
        let currentDate = new Date();
        setChartEndDate(new Date());
        switch (timeline) {
            case '1M':
                currentDate.setMonth(currentDate.getMonth() - 1)
                break
            case '3M':
                currentDate.setMonth(currentDate.getMonth() - 3);
                break
            case '6M':
                currentDate.setMonth(currentDate.getMonth() - 6);
                break
            case '1Y':
                currentDate.setFullYear(currentDate.getFullYear() - 1)
                break
            case '3Y':
                currentDate.setFullYear(currentDate.getFullYear() - 3)
                break
            case '5Y':
                currentDate.setFullYear(currentDate.getFullYear() - 5)
                break
            case 'YTD':
                currentDate.setMonth(0);
                currentDate.setDate(1);
                break
            case 'All':
                // currentDate = new Date('2020-01-01')
                currentDate = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value][0].x;
                break
            default:
                break;
        }
        setTableStartDate(currentDate);
    }
    const handleReset = () => {
        const mapData = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value];
        let data = [{ name: selectType.value, type: "line", data: mapData }];
        let bigLineChartOptionsTemp = { ...bigLineChartOptions }
        bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit }, seriesName: [selectType.label] }];
        bigLineChartOptionsTemp.colors = [selectType.color];
        if (selectType.value === "heatidx") {
            bigLineChartOptionsTemp.annotations = heatIndexAnnotation;
        } else {
            bigLineChartOptionsTemp.annotations = { yaxis: [], xaxis: [] }
        }
        if (isSubscribeUser) {
            if (selectType.value !== "precip" && selectType.value !== "accum_rain_rate") {
                bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                bigLineChartOptionsTemp.annotations.xaxis = [{
                    x: xaxisline,
                    strokeDashArray: 0,
                    borderColor: '#775DD0'
                }];
            }
        }

        setCompareLocationList([]);
        setSelectedCompareTypeOptions([]);
        setChartState(prevState => ({ ...prevState, series: data, options: bigLineChartOptionsTemp, seriesLine: data, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() } } } } }));
    }
    const handleDeleteLocation = (index) => {
        let tempLocationList = [...compareLocationList];
        tempLocationList.splice(index, 1);
        let tempSeries = [...chartState.series];
        tempSeries.splice(index + 1, 1);
        let tempColor = [...chartState.options.colors];
        tempColor.splice(index + 1, 1);
        setCompareLocationList(tempLocationList);
        setChartState(prevState => ({
            ...prevState,
            series: tempSeries,
            options: {
                ...prevState.options,
                colors: tempColor
            }, optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() } } } }
        }));

    }

    const handleNewsKeywordChange = (e) => {
        setNewsKeyword(e.target.value);
    }

    const updateNewsByDate = (timeline) => {
        setSelectionNewsDate(timeline);
        let currentDate = new Date();
        let endDate = new Date();
        setChartEndDate(new Date());
        switch (timeline) {
            case '1M':
                currentDate.setMonth(currentDate.getMonth() - 1);
                break
            case '3M':
                currentDate.setMonth(currentDate.getMonth() - 3);
                break
            case '6M':
                currentDate.setMonth(currentDate.getMonth() - 6);
                break
            case '1Y':
                currentDate.setFullYear(currentDate.getFullYear() - 1);
                break
            case '3Y':
                currentDate.setFullYear(currentDate.getFullYear() - 3);
                break
            case '5Y':
                currentDate.setFullYear(currentDate.getFullYear() - 5);
                break
            case 'YTD':
                currentDate.setMonth(0);
                currentDate.setDate(1);
                break
            case 'All':
                currentDate = null;
                endDate = null;
                break
            default:
                break;
        }
        setNewsStartDateTemp(currentDate);
        setNewsEndDateTemp(endDate);
        // handleNewsKeyword()

    }

    const handleNewsKeyword = () => {
        if (newsKeyword) {
            setNewsLoading(true);
            let categorytemp = newsCategory;
            if (newsCategory === 'All') {
                categorytemp = null;
            }
            GetNews(authToken, categorytemp, country, newsStartDate, newsEndDate, newsKeyword, null).then((jsonData) => {
                setNews(jsonData);
                setNewsLoading(false);
            }).catch((error) => {
                console.log(error);
                setNewsLoading(false);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
        } else {
            if (newsCategory === 'All') {
                if (newsStartDate === null) {
                    setNews(newsAll)
                } else {
                    setNewsLoading(true);
                    GetNews(authToken, null, country, newsStartDate, newsEndDate, null, null).then((jsonData) => {
                        setNews(jsonData);
                        setNewsLoading(false);
                    }).catch((error) => {
                        console.log(error);
                        setNewsLoading(false);
                        if (error.response && error.response.status === 401) {
                            handleLogout();
                        }
                    });
                }
            } else {
                setNewsLoading(true);
                GetNews(authToken, newsCategory, country, newsStartDate, newsEndDate, null, null).then((jsonData) => {
                    setNews(jsonData);
                    setNewsLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setNewsLoading(false);
                    if (error.response && error.response.status === 401) {
                        handleLogout();
                    }
                });
            }
        }

    }

    const handleNewsCategory = (category) => {
        if (category === newsCategory) return;
        setNewsCategory(category);

        GetNews(authToken, category, country, newsStartDate, newsEndDate, newsKeyword, null).then((jsonData) => {
            setNews(jsonData);
            setNewsLoading(false);
        }).catch((error) => {
            console.log(error);
            setNewsLoading(false);
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        });
    }

    const handleDateSearch = () => {
        setNewsStartDate(newsStartDateTemp);
        setNewsEndDate(newsEndDateTemp);

        if (newsKeyword) {
            setNewsLoading(true);
            let categorytemp = newsCategory;
            if (newsCategory === 'All') {
                categorytemp = null;
            }
            GetNews(authToken, categorytemp, country, newsStartDateTemp, newsEndDateTemp, newsKeyword, null).then((jsonData) => {
                setNews(jsonData);
                setNewsLoading(false);
            }).catch((error) => {
                console.log(error);
                setNewsLoading(false);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
        } else {
            if (newsCategory === 'All') {
                if (newsStartDate === null) {
                    setNews(newsAll)
                } else {
                    setNewsLoading(true);
                    GetNews(authToken, null, country, newsStartDateTemp, newsEndDateTemp, null, null).then((jsonData) => {
                        setNews(jsonData);
                        setNewsLoading(false);
                    }).catch((error) => {
                        console.log(error);
                        setNewsLoading(false);
                        if (error.response && error.response.status === 401) {
                            handleLogout();
                        }
                    });
                }
            } else {
                setNewsLoading(true);
                GetNews(authToken, newsCategory, country, newsStartDateTemp, newsEndDateTemp, null, null).then((jsonData) => {
                    setNews(jsonData);
                    setNewsLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setNewsLoading(false);
                    if (error.response && error.response.status === 401) {
                        handleLogout();
                    }
                });
            }
        }

    }

    const handleNewsMoreNew = () => {
        console.log('More News');
        GetNews(authToken, null, country, newsStartDate, newsEndDate, null, news.LastEvaluatedKey).then((jsonData) => {
            if (jsonData.Items.length > 0) {
                let newNews = { LastEvaluatedKey: jsonData.LastEvaluatedKey, Items: [...news.Items, ...jsonData.Items] };
                setNews(newNews);
                setNewsAll(newNews)
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
            }
        });
    }
    const redirectIfIsNotSubscribeUser = () => {
        if (!isSubscribeUser) {
            navigate('/signup');
        }
    }


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className={`${selectionChart === "dateRange" ? "date-range-dropdown date-range-active" : "date-range-dropdown"}`}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>
            <i className={`${selectionChart === "dateRange" ? "fa fa-calendar date-range-active" : "fa fa-calendar"}`} aria-hidden="true" style={{ marginRight: "5px", fontSize: "14px" }} />
            <div className={`${selectionChart === "dateRange" ? "date-range-title date-range-active" : "date-range-title"}`}>Date Range</div>
        </div>
    ));

    const handleLogout = async () => {
        try {
            console.log('Logout');
            await signOut()

            navigate('/login');
        } catch (err) { console.log(err) }
    }

    const [showSubHeader, setShowSubHeader] = useState(false);
    useEffect(() => {
        const showSubHeaderTabList = ['summary', 'news', 'chart', 'historical'];
        if (showSubHeaderTabList.includes(page)) setShowSubHeader(true);
        else setShowSubHeader(false);
    }, [page])

    return (
        <>
            <Navbar expand="md" className="bg-body-tertiary" sticky="top">
                <Container fluid>
                    <Navbar.Brand onClick={() => { navigate("/") }} className="fontweight-700" style={{ cursor: "pointer" }}>The Potomac System</Navbar.Brand>
                    {/* <Navbar.Brand href="/" className="fontweight-700 m-0 p0"><img src="logo.jpeg" height={100} alt="Logo" /></Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            {/* <Nav.Link href="/">Home</Nav.Link> */}
                        </Nav>

                        <Form className="d-flex search-bar" >
                            <Nav className="me-auto">
                                <NavDropdown
                                    title={selectLocationTypeTemp}
                                    id="input-group-dropdown-3"
                                >

                                    {locationRadios.map((radio, idx) => (
                                        <Dropdown.Item key={idx} href="#" onClick={() => setSelectLocationTypeTemp(radio.value)}>{radio.name}</Dropdown.Item>
                                    ))}

                                </NavDropdown>
                            </Nav>
                            <InputGroup>
                                {selectLocationTypeTemp === 'Location' ? (
                                    <>
                                        <InputGroup.Text>Lat:</InputGroup.Text>
                                        <Form.Control
                                            value={latitudeTemp}
                                            onChange={handleLatitudeChange}
                                            aria-label="Latitude"
                                        />
                                        <InputGroup.Text>Lon:</InputGroup.Text>
                                        <Form.Control
                                            value={longitudeTemp}
                                            onChange={handleLongitudeChange}
                                            aria-label="Longitude"
                                        />
                                        <Button variant="secondary" onClick={() => handleLocationSearch()}><i className="pi pi-search" style={{ fontWeight: '900' }}></i></Button>
                                    </>
                                ) : (
                                    <>
                                        <Select
                                            className="basic-single flex-grow-1 select-country" // Use flex-grow-1 class to make Select fill remaining space
                                            classNamePrefix="select"
                                            value={selectedCountryTemp}
                                            onChange={handleCountryChange}
                                            options={citylist}
                                            width={200}
                                            isSearchable
                                        />
                                        <Button className="search-button" variant="secondary" onClick={() => handleLocationSearch()}><i className="pi pi-search" style={{ fontWeight: '900' }}></i></Button>
                                    </>
                                )}
                            </InputGroup>
                        </Form>
                        {isUser ?
                            <NavDropdown title={username} id="nav-dropdown" style={{ textAlign: "center", marginLeft: "10px" }} align={{ lg: 'end' }}>
                                <NavDropdown.Item eventKey="4.1" onClick={() => { handleLogout() }}>Sign Out</NavDropdown.Item>
                            </NavDropdown> : <><Nav.Link href="/signup" className='mx-3' style={{ textAlign: "center", fontWeight: "600" }}>SUBSCRIBE</Nav.Link><Nav.Link href="/login" style={{ textAlign: "center", fontWeight: "600" }}>LOG IN</Nav.Link></>}
                        {/* <i className="fa fa-sign-out-alt signout" aria-hidden="true" onClick={() => { handleLogout() }}></i> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="app mx-auto home">
                <div className="header-tab">
                    <div className={`title ${page === "summary" ? "active" : ""}`} onClick={() => setPage("summary")}> Summary </div>
                    {/* <div className={`title ${page === "news" ? "active" : ""}`} onClick={() => setPage("news")}> News </div> */}
                    <div className={`title ${page === "chart" ? "active" : ""}`} onClick={() => setPage("chart")}> Chart </div>
                    <div className={`title ${page === "historical" ? "active" : ""}`} onClick={() => setPage("historical")}> Historical Data </div>
                    {enableArticle && <div className={`title ${page === "article" ? "active" : ""}`} onClick={() => setPage("article")}> Articles </div>}
                    {enableDataLabeling && <div className={`title ${page === "data-labeling" ? "active" : ""}`} onClick={() => setPage("data-labeling")}> Data Labeling </div>}
                </div>
                {showSubHeader && <div>
                    <h4 className="m-0" style={{ fontWeight: 900, fontSize: "1.5rem" }}>{selectLocationType === 'Location' ? `Latitude: ${latitude} Longitude: ${longitude}` : selectedCountry.label}</h4>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: '1px solid #DEE0E4', paddingBottom: ".5rem", marginBottom: ".5rem" }}>
                        <p className="" style={{ fontSize: ".75rem", margin: 0 }}>Latitude: {latitude} Longitude: {longitude}</p>
                        <a className="report-system-a" href={mailtoLink} style={{ display: "flex", alignItems: "center" }}>
                            <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "5px" }} />Request new features and data
                        </a>
                    </div>

                    {/* <h3 className="value-header">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData[selectType.value]} <p className={`diff-header ${transformData[`${latitude}_${longitude}`].summaryData[selectType.value + "dif"]>=0?"plus":""}`}>{transformData[`${latitude}_${longitude}`].summaryData[selectType.value + "dif"]}</p></> : ""} </h3> */}
                    <h3 className="value-header">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData[selectType.value]} </> : "-"} </h3>
                    <p className="" style={{ fontSize: ".75rem" }}>At {changetimestamptodatePattern(latestDate)} UTC</p>
                    {/* <div className="header-tab">
            <div className={`title ${page === "summary" ? "active" : ""}`} onClick={() => setPage("summary")}> Summary </div>
            <div className={`title ${page === "chart" ? "active" : ""}`} onClick={() => setPage("chart")}> Chart </div>
            <div className={`title ${page === "historical" ? "active" : ""}`} onClick={() => setPage("historical")}> Historical Data </div>
            </div> */}
                </div>}
                {page === 'summary' ? (
                    <div className="content-tab">
                        <div className="container-summary m-0 p-0">
                            <div className="left-col">

                                <DropdownButton
                                    className="dropdown-type-summary"
                                    variant="white"
                                    size="md"
                                    as={ButtonGroup}
                                    title={`Type: ${selectType.label}`}
                                    id="input-group-dropdown-3"
                                >
                                    {typeOptions.map(option => (
                                        <Dropdown.Item key={option.value} href="#" onClick={() => handleTypeChange(option)}>
                                            {option.label}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>

                                <DropdownButton
                                    className="dropdown-type-chart mx-2"
                                    variant="white"
                                    size="sm"
                                    as={ButtonGroup}
                                    title={`Indicators: ${selectedIndicatior.label}`}
                                    id="input-group-dropdown-3"
                                >
                                    {indicatiorOption.map(option => (
                                        <Dropdown.Item key={option.value} href="#" onClick={() => handleIndicatiorChange(option)}>
                                            {option.label}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card left">
                                            <p className="summary-card-title">Heat Index - Latest</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.heatidx} </> : "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card">
                                            <p className="summary-card-title">Heat Index - 52 Week Range</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.heatidx52Range} </> : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card left">
                                            <p className="summary-card-title">Temperature - Latest</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.temp2m} </> : "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card">
                                            <p className="summary-card-title">Temperature - 52 Week Range</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.temp2m52Range} </> : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card left">
                                            <p className="summary-card-title">Surface Temperature - Latest</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.temp} </> : "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card">
                                            <p className="summary-card-title">Surface Temperature - 52 Week Range</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.temp52Range} </> : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card left">
                                            <p className="summary-card-title">Relative Humidity - Latest</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.relhum} </> : "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card">
                                            <p className="summary-card-title">Relative Humidity - 52 Week Range</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.relhum52Range} </> : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card left">
                                            <p className="summary-card-title">Precipitable Water - Latest</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.tpw} </> : "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card">
                                            <p className="summary-card-title">Precipitable Water - 52 Week Range</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.tpw52Range} </> : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card left">
                                            <p className="summary-card-title">Rain Rate - Latest</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.precip} </> : "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card">
                                            <p className="summary-card-title">Rain Rate - 52 Week Range</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.precip52Range} </> : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="summary-card-last left">
                                            <p className="summary-card-title">Annual Accumulated Rain Rate</p>
                                            <p className="summary-card-detail">{transformData[`${latitude}_${longitude}`] ? <>{transformData[`${latitude}_${longitude}`].summaryData.accum_rain_rate} </> : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-col">

                                <div id="chart">
                                    <div className="head-chart" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="toolbar">
                                            {selectionRadios.map((radio, idx) => (
                                                <h6 key={idx} className={`button-summary ${selection === radio ? "active" : ""}`} onClick={(e) => updateData(radio)}>
                                                    {radio}
                                                </h6>
                                            ))}
                                        </div>
                                        {selectType.label === 'Heat Index' ? <i className="fa fa-info-circle info-button" aria-hidden="true" onClick={() => setModalShow(true)} /> : <></>}
                                    </div>
                                    {chartLoading ? (
                                        <div className="loading-indicator">Loading...</div>
                                    ) : (
                                        <div id="chart-timeline">
                                            <Chart options={chartState.miniChartOptions} series={chartState.miniChartSeries} type="area" height={285} />
                                        </div>
                                    )}
                                    <div className="reference">NOAA Global Forecast System (GFS) was accessed from https://registry.opendata.aws/noaa-gfs-bdp-pds.</div>
                                </div>


                            </div>
                        </div>
                    </div>
                ) : (page === 'chart' ? (
                    <div className="content-tab">
                        <div style={{ margin: '10px 0px 0px 0px', display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <DropdownButton
                                    className="dropdown-type-chart-type"
                                    variant="white"
                                    size="md"
                                    as={ButtonGroup}
                                    title={`Type: ${selectType.label}`}
                                    id="input-group-dropdown-3"
                                >
                                    {typeOptions.map(option => (
                                        <Dropdown.Item key={option.value} href="#" onClick={() => handleTypeChange(option)}>
                                            {option.label}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                                <div style={{ display: 'inline', marginRight: '10px' }}></div>

                                <DropdownButton
                                    className="dropdown-type-chart"
                                    variant="white"
                                    size="sm"
                                    as={ButtonGroup}
                                    title={`Indicators: ${selectedIndicatior.label}`}
                                    id="input-group-dropdown-3"
                                >
                                    {indicatiorOption.map(option => (
                                        <Dropdown.Item key={option.value} href="#" onClick={() => handleIndicatiorChange(option)}>
                                            {option.label}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                                <div style={{ display: 'inline', marginRight: '10px' }}></div>
                                <DropdownButton
                                    id="dropdown-checkbox"
                                    className="dropdown-type-chart"
                                    variant="white"
                                    size="sm"
                                    as={ButtonGroup}
                                    show={isCompareDropdownOpen}
                                    onToggle={(isOpen) => setIsCompareDropdownOpen(isOpen)}
                                    style={isSubscribeUser ? {} : { filter: "blur(2px)" }}
                                    onClick={() => redirectIfIsNotSubscribeUser()}
                                    title={
                                        <>
                                            Comparison
                                            {/* <Badge bg="secondary">{selectedCompareTypeOptions.length}</Badge> */}
                                        </>
                                    }
                                >
                                    <Dropdown.Header>
                                        <ButtonGroup style={{ width: '100%' }}>
                                            {indicatiorRadios.map((radio, idx) => (
                                                <ToggleButton
                                                    key={idx}
                                                    id={`radio-${idx}`}
                                                    className="compare-radio-button"
                                                    type="radio"
                                                    variant="outline-secondary"
                                                    name="radio"
                                                    value={indicatiorRadioValue.value}
                                                    checked={indicatiorRadioValue === radio.value}
                                                    onChange={(e) => setIndicatiorRadioValue(radio.value)}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </Dropdown.Header>
                                    {indicatiorRadioValue === 'variables' ? compareTypeOption.map(option => (
                                        <Dropdown.Item key={option.value} eventKey={option.value} onClick={() => handleCompareTypeChange(option)}>
                                            <Form.Check
                                                type="checkbox"
                                                label={option.label}
                                                checked={selectedCompareTypeOptions.includes(option)}
                                                readOnly
                                            />
                                        </Dropdown.Item>
                                    )) : (indicatiorRadioValue === 'locations' ?
                                        <Dropdown.Header>
                                            <ButtonGroup style={{ width: '100%' }}>

                                                {locationRadios.map((radio, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        type="radio"
                                                        variant="outline-secondary"
                                                        value={radio.value}
                                                        checked={selectCompareLocationType === radio.value}
                                                        onClick={(e) => setSelectCompareLocationType(radio.value)}
                                                        style={selectCompareLocationType === radio.value ? { borderColor: '#828A92', color: 'white', backgroundColor: '#828A92' } : { borderColor: '#828A92', color: '#828A92', backgroundColor: 'transparent' }} // Adjust styles here
                                                    >
                                                        {radio.name}
                                                    </ToggleButton>
                                                ))}
                                            </ButtonGroup>
                                            <InputGroup style={{ width: '100%', marginTop: '10px' }}>
                                                {selectCompareLocationType === 'Location' ? (
                                                    <>
                                                        <InputGroup.Text>Lat:</InputGroup.Text>
                                                        <Form.Control
                                                            value={compareLatitude}
                                                            onChange={handleCompareLatitudeChange}
                                                            aria-label="Latitude"
                                                        />
                                                        <InputGroup.Text>Lon:</InputGroup.Text>
                                                        <Form.Control
                                                            value={compareLongitude}
                                                            onChange={handleCompareLongitudeChange}
                                                            aria-label="Longitude"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Select
                                                            className="basic-single flex-grow-1" // Use flex-grow-1 class to make Select fill remaining space
                                                            classNamePrefix="select"
                                                            value={selectedCompareCountry}
                                                            onChange={handleCompareCountryChange}
                                                            options={citylist}
                                                            width={200}
                                                            isSearchable // Add this if you want a searchable Select
                                                        />
                                                    </>
                                                )}

                                            </InputGroup >
                                            {compareLocationList.map((location, index) => (<div key={index} className="location-cancel-div"><i className="fa fa-times location-cancel-button" aria-hidden="true" onClick={() => handleDeleteLocation(index)} /> {location.type === "Location" ? <>lat-lon:({location.lat},{location.lon})</> : <>Location:{location.location}({location.lat},{location.lon})</>}</div>))}
                                            <Button style={{ width: '100%', marginTop: '10px' }} variant="secondary" onClick={() => handleAddCompareLocation()}>Add</Button>
                                        </Dropdown.Header>
                                        :
                                        <Dropdown.Header>

                                            <div className="compare-div-mini-yoy">
                                                {/*   <ButtonGroup style={{ width: '100%' }}> */}
                                                {yearOptions.map((radio, idx) => (
                                                    <Button
                                                        key={idx}
                                                        type="radio"
                                                        variant="outline-secondary"
                                                        className="compare-radio-button-yoy"
                                                        name="radio"
                                                        value={radio}
                                                        onClick={() => handleYearChange(radio)}
                                                        style={yearList.includes(radio) ? { borderColor: '#828A92', color: 'white', backgroundColor: '#828A92' } : { borderColor: '#828A92', color: '#828A92', backgroundColor: 'transparent' }} // Adjust styles here
                                                    >
                                                        {radio}
                                                    </Button>
                                                ))}
                                                {/* </ButtonGroup> */}
                                            </div>
                                            <Button className="compare-button-yoy" variant="secondary" onClick={() => handleHistoryCompare()}>Compare</Button>
                                        </Dropdown.Header>)}
                                </DropdownButton>
                                <div className="btn-group reset-button-home" onClick={() => handleReset()}>Reset</div>
                            </div>
                            {selectType.label === 'Heat Index' ? <i className="fa fa-info-circle info-button" aria-hidden="true" onClick={() => setModalShow(true)} /> : <></>}
                        </div>
                        <div className="row">
                            <div>

                                {chartLoading ? (
                                    <div className="loading-indicator-2">Loading...</div>
                                ) : (
                                    <div id="wrapper">
                                        <div id="chart-line2">
                                            <Chart options={chartState.options} series={chartState.series} type="line" height={430} />
                                        </div>
                                        <div id="chart-line">
                                            <Chart options={chartState.optionsLine} series={chartState.seriesLine} type="area" height={130} />
                                        </div>
                                    </div>
                                )}
                                <div id="html-dist"></div>
                            </div>
                        </div>
                        <div className="chart-time">
                            {selectionRadios.map((radio, idx) => (
                                <h6 key={idx} className={`button-chart ${selectionChart === radio ? "active" : ""}`} onClick={(e) => updateChartData(radio)}>
                                    {radio}
                                </h6>
                            ))}
                            <div style={{ display: "inline-block" }}>
                                <Dropdown
                                    show={isDropdownOpen}
                                    onToggle={(isOpen) => setIsDropdownOpen(isOpen)}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />

                                    <Dropdown.Menu>
                                        <div className="date-range-dropdown-calendar">
                                            Start Date
                                            <div className="">
                                                <DatePicker
                                                    className="dropdown-date-picker"
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={new Date('2015-06-22')}
                                                    maxDate={chartEndDate}
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled,
                                                    }) => (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className="select-calendar-arrow">
                                                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                            </button>
                                                            <select
                                                                className="select-calendar"
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                            >
                                                                {yearOptions.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className="select-calendar"
                                                                value={months[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(months.indexOf(value))
                                                                }
                                                            >
                                                                {months.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    )}
                                                    selected={chartStartDate}
                                                    onChange={(date) => setChartStartDate(date)}
                                                /></div>
                                            End Date
                                            <div>
                                                <DatePicker
                                                    minDate={chartStartDate}
                                                    maxDate={new Date()}
                                                    className="dropdown-date-picker"
                                                    dateFormat="dd/MM/yyyy"
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled,
                                                    }) => (
                                                        <div
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                                className="select-calendar-arrow">
                                                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                            </button>
                                                            <select
                                                                className="select-calendar"
                                                                value={date.getFullYear()}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                            >
                                                                {yearOptions.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                className="select-calendar"
                                                                value={months[date.getMonth()]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(months.indexOf(value))
                                                                }
                                                            >
                                                                {months.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    )}
                                                    selected={chartEndDate}
                                                    onChange={(date) => setChartStartDate(date)}
                                                />
                                            </div>
                                        </div>
                                        <div className="dropdown-table-button-div">
                                            <Button className="dropdown-table-button" variant="secondary" onClick={() => handleChartDateRangeSearch()}>Search</Button>
                                            <Button className="dropdown-table-button" variant="outline-secondary" onClick={() => handleCancelClick()}>Cancel</Button>
                                        </div>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </div>
                            <div className="reference-container">
                                <div className="reference calender">NOAA Global Forecast System (GFS) was accessed from https://registry.opendata.aws/noaa-gfs-bdp-pds.</div>
                            </div>
                        </div>

                    </div>) : page === 'historical' ? (
                        <div className="content-tab">

                            <DropdownButton
                                className="dropdown-type-history"
                                variant="white"
                                size="sm"
                                as={ButtonGroup}
                                title={`Type: ${selectType.label}`}
                                id="input-group-dropdown-3"
                            >
                                {typeOptions.map(option => (
                                    <Dropdown.Item key={option.value} href="#" onClick={() => handleTypeChange(option)}>
                                        {option.label}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                            <DropdownButton
                                show={isTableDropdownOpen}
                                onToggle={(isOpen) => setIsTableDropdownOpen(isOpen)}
                                className="dropdown-type-history"
                                variant="white"
                                size="sm"
                                as={ButtonGroup}
                                title={`${formatDate(tableStartDate)} - ${formatDate(tableEndDate)}`}
                                id="input-group-dropdown-3"
                            >
                                <div className="dropdown-type-table-div">
                                    {selectionRadios.map((radio, idx) => (
                                        <h6 key={idx} className={`button-summary ${selectionTable === radio ? "active" : ""}`} onClick={(e) => updateTableData(radio)}>
                                            {radio}
                                        </h6>
                                    ))}
                                </div>

                                <div className="date-range-dropdown-calendar">
                                    Start Date
                                    <div className="table-date-range-dropdown">
                                        <DatePicker
                                            className="dropdown-date-picker"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date('2015-06-22')}
                                            maxDate={tableEndDate}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                            }) => (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                        className="select-calendar-arrow">
                                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                    </button>
                                                    <select
                                                        className="select-calendar"
                                                        value={date.getFullYear()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                    >
                                                        {yearOptions.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        className="select-calendar"
                                                        value={months[date.getMonth()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(months.indexOf(value))
                                                        }
                                                    >
                                                        {months.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            )}
                                            selected={tableStartDate}
                                            onChange={(date) => setTableStartDate(date)}
                                        /></div>
                                    End Date
                                    <div>
                                        <DatePicker
                                            className="dropdown-date-picker"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={tableStartDate}
                                            maxDate={new Date()}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                            }) => (
                                                <div
                                                    style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                        className="select-calendar-arrow">
                                                        <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                    </button>
                                                    <select
                                                        className="select-calendar"
                                                        value={date.getFullYear()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                    >
                                                        {yearOptions.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <select
                                                        className="select-calendar"
                                                        value={months[date.getMonth()]}
                                                        onChange={({ target: { value } }) =>
                                                            changeMonth(months.indexOf(value))
                                                        }
                                                    >
                                                        {months.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            )}
                                            selected={tableEndDate}
                                            onChange={(date) => setTableEndDate(date)}
                                        />
                                    </div>
                                    <div className="dropdown-table-button-div">
                                        <Button className="dropdown-table-button" variant="secondary" onClick={() => handleTableDateRangeSearch()}>Done</Button>
                                        <Button className="dropdown-table-button" variant="outline-secondary" onClick={() => handleTableDateRangeCancel()}>Cancel</Button>
                                    </div>
                                </div>
                            </DropdownButton>
                            <div className="d-flex align-items-center gap-2 justify-content-end">
                                {isSubscribeUser ?
                                    <div className="d-flex gap-2">
                                        {/* <ButtonPrime className="rounded-button" type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                  <ButtonPrime className="rounded-button" type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                  <ButtonPrime className="rounded-button" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                                        <div className="download-tab" onClick={() => exportCSV(false)}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> CSV</div>
                                        <div className="download-tab" onClick={exportExcel}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> EXCEL</div>
                                        <div className="download-tab" onClick={exportPdf}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> PDF</div>
                                    </div>
                                    :
                                    <>
                                        <div className="d-flex gap-2" onClick={() => { navigate('/signup'); }}>
                                            {/* <div className="download-lock-tab" > <img src="/padlock.png" height={20} alt="Lock Icon" /></div> */}
                                            <div className="download-lock-tab" style={{ filter: "blur(2px)" }} ><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> CSV</div>
                                            <div className="download-lock-tab" style={{ filter: "blur(2px)" }} ><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> EXCEL</div>
                                            <div className="download-lock-tab" style={{ filter: "blur(2px)" }}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> PDF</div>
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="card">
                                <Tooltip target=".export-buttons>button" position="bottom" />


                                <DataTable ref={dt} value={tableData.data} selectionMode="single" tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[10, 25, 50]}>
                                    {tableData.cols.map((col, index) => (
                                        <Column key={index} field={col.field} header={col.header} sortable />
                                    ))}
                                </DataTable>
                            </div>

                            <div className="reference-table">NOAA Global Forecast System (GFS) was accessed from https://registry.opendata.aws/noaa-gfs-bdp-pds.</div>
                        </div>
                    ) : page === 'article' ? <ArticleList articles={articles} loading={loadingArticles} /> 
                    : page === 'data-labeling' ? <DataLabelingPage /> : 
                    <div className="row">
                        <div className="col-md-12">
                            <div style={isSubscribeUser ? {} : { filter: "blur(2px)" }} onClick={() => redirectIfIsNotSubscribeUser()}>
                                <DropdownButton
                                    show={isNewsDropdownOpen}
                                    onToggle={(isOpen) => setIsNewsDropdownOpen(isOpen)}
                                    className="dropdown-news"
                                    variant="white"
                                    size="sm"
                                    as={ButtonGroup}
                                    title={`Date: ${newsStartDate ? formatDate(newsStartDate) + " - " + formatDate(newsEndDate) : "All"}`}
                                    id="input-group-dropdown-3"
                                    disabled={!isSubscribeUser}
                                >
                                    <div className="dropdown-type-table-div">
                                        {selectionRadios.map((radio, idx) => (
                                            <h6 key={idx} className={`button-summary ${selectionNewsDate === radio ? "active" : ""}`} onClick={(e) => updateNewsByDate(radio)}>
                                                {radio}
                                            </h6>
                                        ))}
                                    </div>

                                    <div className="date-range-dropdown-calendar">
                                        Start Date
                                        <div className="table-date-range-dropdown">
                                            <DatePicker
                                                className="dropdown-date-picker"
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date('2015-06-22')}
                                                maxDate={tableEndDate}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                            className="select-calendar-arrow">
                                                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                        </button>
                                                        <select
                                                            className="select-calendar"
                                                            value={date.getFullYear()}
                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                        >
                                                            {yearOptions.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <select
                                                            className="select-calendar"
                                                            value={months[date.getMonth()]}
                                                            onChange={({ target: { value } }) =>
                                                                changeMonth(months.indexOf(value))
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                )}
                                                selected={newsStartDateTemp}
                                                onChange={(date) => setNewsStartDateTemp(date)}
                                            /></div>
                                        End Date
                                        <div>
                                            <DatePicker
                                                className="dropdown-date-picker"
                                                dateFormat="dd/MM/yyyy"
                                                minDate={tableStartDate}
                                                maxDate={new Date()}
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                            className="select-calendar-arrow">
                                                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                        </button>
                                                        <select
                                                            className="select-calendar"
                                                            value={date.getFullYear()}
                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                        >
                                                            {yearOptions.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <select
                                                            className="select-calendar"
                                                            value={months[date.getMonth()]}
                                                            onChange={({ target: { value } }) =>
                                                                changeMonth(months.indexOf(value))
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                )}
                                                selected={newsEndDateTemp}
                                                onChange={(date) => setNewsEndDateTemp(date)}
                                            />
                                        </div>
                                        <div className="dropdown-table-button-div">
                                            <Button className="dropdown-table-button" variant="secondary" onClick={() => handleDateSearch()}>Done</Button>
                                            <Button className="dropdown-table-button" variant="outline-secondary" onClick={() => setIsNewsDropdownOpen(false)}>Cancel</Button>
                                        </div>
                                    </div>
                                </DropdownButton>

                                <DropdownButton
                                    className="dropdown-news"
                                    variant="white"
                                    size="sm"
                                    as={ButtonGroup}
                                    title={`Category: ${newsCategory}`}
                                    id="input-group-dropdown-3"
                                    disabled={!isSubscribeUser}
                                >
                                    {newsCategoryList.map(option => (
                                        <Dropdown.Item key={option} href="#" onClick={() => handleNewsCategory(option)}>
                                            {option}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>

                                <div className="keyword-search">
                                    <InputGroup className="mb-1">
                                        <InputGroup.Text>Keyword:</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Keyword"
                                            aria-label="Keyword"
                                            aria-describedby="basic-addon2"
                                            type="search"
                                            value={newsKeyword}
                                            onChange={handleNewsKeywordChange}

                                            disabled={!isSubscribeUser}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => { handleNewsKeyword() }}
                                            disabled={!isSubscribeUser}>
                                            Search
                                        </Button>
                                    </InputGroup>
                                </div>
                            </div>
                            { isSubscribeUser ? (newsLoading ? ( 
                                <div className="news-loading">Loading...</div>
                            ) : news.Items.length === 0 ? <div className="news-loading">Not Found </div> : (
                                <div className="row">
                                    {news.Items.map((item, index) => (
                                        <div className="news-item col-md-12" key={index}>
                                            <div className="news-date">{item.publisher_name} : {timeAgo(item.publication_date)}</div>
                                            <div className="news-title" onClick={() => { navigate("/new-detail/" + item.an) }}>{item.title}</div>
                                            {/* <div className="news-content">{ !item.snippet || item.snippet.length < 300 ? item.snippet : `${item.snippet.substring(0, 300)}...`}</div> */}
                                            <div className="news-content">{item.snippet}</div>
                                        </div>
                                    ))}
                                    {isSubscribeUser && newsCategory === "All" && news.LastEvaluatedKey && !newsKeyword ? (
                                        <div className="news-more-container">
                                            <div className="news-more" onClick={() => { handleNewsMoreNew() }} >More News</div>
                                        </div>
                                    ) : null}
                                </div>
                            )):<div style={{marginTop:"10px"}}><ContentLocker /></div>}
                        </div>
                    </div>)}

                {page === 'summary' ? (
                    <div className="row">
                        <div className="map-container">
                            {changeLocation ? (
                                <div className="loading-indicator">Loading...</div>
                            ) : (
                                <MapContainer center={[latitude, longitude]} zoom={13} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={[latitude, longitude]}>
                                        <Popup>
                                            A pretty CSS3 popup. <br /> Easily customizable.
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            )}
                        </div>
                        {/* <h4 className="news-header">Weekly Climate Outlook:</h4>
                        {isSubscribeUser ?
                            (isLoadingClimate ? (
                                <div className="climate-loading">Loading...</div>
                            ) : (
                                climateData.length === 0 ? (
                                    <div className="climate-not-found">Not Found</div>
                                ) : (
                                    <div className="row">
                                        {climateData.map((item, index) => (
                                            <div className="news-item col-md-12" key={index}>
                                                <div className="news-date"> {formatDate(item.date)}</div>
                                                <div className="news-content">{processTextToBullets(item.data)}</div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            ))
                            : <ContentLocker />} */}
                        {/* <div className="col-md-12">
                            <h4 className="news-header">Recommended News:</h4>
                            { isSubscribeUser ? (newsLoading ? (
                                <div className="news-loading">Loading...</div>
                            ) : (
                                <div className="row">
                                    {news.Items.slice(0, 10).map((item, index) => (
                                        <div className="news-item col-md-12" key={index}>
                                            <div className="news-date">{item.publisher_name} : {timeAgo(item.publication_date)}</div>
                                            <div className="news-title" onClick={() => { navigate("/new-detail/" + item.an) }}>{item.title}</div>
                                            <div className="news-content">{item.snippet}</div>
                                        </div>
                                    ))}
                                    {isSubscribeUser && newsCategory === "All" && news.LastEvaluatedKey && !newsKeyword ? (
                                        <div className="news-more-container">
                                            <div className="news-more" onClick={() => { setPage("news") }} >More News</div>
                                        </div>
                                    ) : null}
                                </div>
                            )): <ContentLocker />}
                        </div> */}
                    </div>) : null}
            </div>
            <ModelForHeatIndex
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default HistoryPage;
