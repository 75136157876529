import React from "react";
import ArticleBox from "./ArticleBox";
import "./css/ArticleList.css";
import Loading from "./Loading";

const ArticleList = ({ articles, loading }) => {

  return (
    loading ? (
      <div style={{ marginTop: "10%" }}>
        <Loading />
      </div>
    ) :
      (<div>
        <h1>Articles</h1>
        <div className="container">
          {/* <div className="d-grid gap-3"> */}
          {articles.map((article, index) => (
            <ArticleBox
              key={index}
              id={article.id}
              title={article.title}
              level={article.level}
              description={article.description}
              authorName={article.authorName}
              time={article.time}
            />
          ))}
        </div>
      </div>)
  );
};

export default ArticleList;
