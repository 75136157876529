// store.js
import { configureStore } from "@reduxjs/toolkit";
import articleSlice from "./features/article/articleSlice";
import dataLabelingSlice from "./features/dataLabeling/dataLabelingSlice";

const store = configureStore({
  reducer: {
    article: articleSlice,
    dataLabeling: dataLabelingSlice,
    // Add other reducers here
  },
});

export default store;
