import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HistoryPage from "./pages/HistoryPage";
import NotFoundPage from "./pages/NotFoundPage";
import NewDetailPage from "./pages/NewDetailPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ChartPage from "./pages/ChartPage";
import Contacts from "./components/contacts/Contacts";
import ArticlePage from "./pages/ArticlePage";

const RoutesPage = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/" element={<HistoryPage />} />
        <Route path="/new-detail/:id" element={<NewDetailPage />} />
        <Route path="/chart/:token" element={<ChartPage />} />
        <Route path="/article/:id" element={<ArticlePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default RoutesPage;
