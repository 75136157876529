import React from 'react';
import './css/Loading.css';

const Loading = () => {
    return (
        <div className='spinner-container'>
            <div className="spinner" />
            <span>Loading...</span>
        </div>
    );
};

export default Loading;
